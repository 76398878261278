import axios from "axios";

const backUrl = process.env.REACT_APP_BACKURL

export const fetchData = async (surveyId) => {
  try {
    const response = await axios.get(`${backUrl}/user/survey/${surveyId}`);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

// Function to update elements with type 'hear_voice'
export const updateHearVoiceType = (survey) => {
  const titleHearVoice = survey.pages[0].elements.find(item => item.type === 'hear_voice')?.title || null
  let newElement;
  if (titleHearVoice) {
    newElement = {
      "type": "imagepicker",
      "name": "HEAR_VOICE",
      "title": titleHearVoice,
      "choices": [
        {
          "value": "true",
          "imageLink": "https://i.ibb.co/zmw7cTh/speaker.png"
        }
      ],
      "multiSelect": true,
      "imageFit": "cover",
      "imageHeight": 50,
      "imageWidth": 50,
    };
  }

  survey["progressBarType"] = "questions"
  // Loop through the pages
  survey.pages.forEach(page => {
    // Loop through the elements in each page
    page.elements.forEach((element, index) => {
      // Check if the type is 'hear_voice'
      if (element.type === 'hear_voice') {
        // Replace the element with the new object
        page.elements[index] = newElement;
      }
    });
  });

  return survey;
}

export const saveAnswers = async (answers) => {
  try {
    const response = await axios.post(`${backUrl}/user/submitSurvey`, answers);
    console.log("response", response)
    return response.data;
  } catch (error) {
    console.error('Error during login:', error);
    throw error;
  }
};