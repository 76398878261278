import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as Survey from "survey-react-ui";
// import "survey-core/modern.min.css";
import "survey-core/defaultV2.min.css";
import * as SurveyTheme from "survey-core/themes";
import { Model, SvgRegistry } from "survey-core";
import { fetchData, saveAnswers, updateHearVoiceType } from "./utils";
import { iconSpeakerSVG } from "./icons";
import { Converter } from "showdown";

// Register the custom SVG icon


SvgRegistry.registerIconFromSvg("speaker-icon-show", iconSpeakerSVG);
const playAudio = (title) => {
  const utterance = new SpeechSynthesisUtterance(title);
  speechSynthesis.speak(utterance);
};


const SurveyComponent = () => {
  const { surveyId } = useParams();
  const [survID, setSurvId] = useState(null);
  const [respondentId, setRespondentId] = useState(null);
  const [surveyModel, setSurveyModel] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    setLoading(true);
    // Fetch the survey JSON from the API
    const getData = async () => {
      try {
        //const token = await login();
        const fetchedData = await fetchData(surveyId);
        console.log("comp", fetchedData._id)
        setSurvId(fetchedData._id)
        if (fetchedData.hasOwnProperty('userId')) {
          setRespondentId(fetchedData.userId);
        }
        else{
          setRespondentId(null);
        }
        setSurveyModel(new Model(updateHearVoiceType(fetchedData)));
        setLoading(false);

      } catch (error) {
        console.error('Error in getData:', error);
        setError('Failed to load survey.');
        setLoading(false);
      }
    };

    getData();
  }, [surveyId]);

  useEffect(() => {
    if (surveyModel) {
      surveyModel.applyTheme(SurveyTheme.SharpLight);
      const updateAllQuestionsTitleActions = () => {
        const triggerQuestion = surveyModel.getQuestionByName("HEAR_VOICE");
        if (!triggerQuestion) return;

        surveyModel.getAllQuestions().forEach((question) => {
          if (question.name !== "HEAR_VOICE") {
            question.updateTitleActions();
          }
        });
      };
      const updateProgress = () => {
        const totalQuestions = surveyModel.getAllQuestions().length;
        const answeredQuestions = surveyModel.getAllQuestions().filter(q => q.isAnswered).length;
        const progressValue = (answeredQuestions / totalQuestions) * 100;
        setProgress(progressValue);
      };

      surveyModel.onValueChanged.add(updateProgress);
      surveyModel.onAfterRenderSurvey.add(updateProgress);
      surveyModel.onValueChanged.add((sender, options) => {
        if (options.name === "HEAR_VOICE") {
          updateAllQuestionsTitleActions();
        }
      });
      const converter = new Converter();
      surveyModel.onTextMarkdown.add(function (survey, options) {
        let str = converter.makeHtml(options.text);
        str = str.substring(3);
        str = str.substring(0, str.length - 4);
        options.html = str;
      });
      surveyModel.onGetQuestionTitleActions.add((sender, options) => {
        const triggerQuestion = surveyModel.getQuestionByName("HEAR_VOICE");
        if (triggerQuestion && triggerQuestion.value[0] === "true") {
          const actionIndex = options.titleActions.findIndex(action => action.id === "speaker-icon-show");
          if (actionIndex === -1) {
            options.titleActions.push({
              id: "speaker-icon-show",
              iconName: "icon-speaker-icon-show",
              action: () => playAudio(options.question.title),
            });
          }
        } else {
          const actionIndex = options.titleActions.findIndex(action => action.id === "speaker-icon-show");
          if (actionIndex !== -1) {
            options.titleActions.splice(actionIndex, 1);
          }
        }
      });
      surveyModel.onComplete.add(async (sender, options) => {
        options.showSaveInProgress();
        const finalAnswers = {
          "surveyId": survID,
          "respondent": respondentId,
          "answers": [sender.data]
        }
        const result = await saveAnswers(finalAnswers);
        if (result.message === 'Survey Submitted Successfully' && result.status) {
          options.showSaveSuccess();
        } else {
          options.showSaveError();
        }
      })
      // Initial update to ensure actions are set based on initial values
      updateAllQuestionsTitleActions();
    }
  }, [surveyModel]);

  if (loading) {
    return <div>Loading survey...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        backgroundImage: 'linear-gradient(to right, #666fcc, #9c77d3)',
        borderRadius: '100px',
        width: `${progress}%`,
        height: '5px',
        zIndex: 1000
      }}></div>
      {surveyModel ? <Survey.Survey model={surveyModel} /> : "No survey available."}
    </div>
  );
};

export default SurveyComponent;
