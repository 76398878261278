import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SurveyComponent from './SurveyComponent';
import "survey-core/defaultV2.min.css";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/:surveyId" element={<SurveyComponent />} />
      </Routes>
    </Router>
  );
}

export default App;
